import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'react-bootstrap-icons'; // 使用 Bootstrap 图标
import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/SendResetEmail?userEmail=${encodeURIComponent(email)}`, {
                method: 'POST',
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.detail || 'Request failed');
            }

            setShowSuccess(true);
            setShowError(false);
            setErrorMessage('');
            setEmail('');
        } catch (error) {
            console.error('Request error:', error);
            setShowError(true);
            setShowSuccess(false);
            setErrorMessage(error.message || 'Failed to submit request. Please try again.');
        }
    };

    useEffect(() => {
        if (email !== '') setShowError(false);
    }, [email]);

    return (
        <div style={{ width: '100vw', height: '100vh' }} className='d-flex justify-content-center align-items-center'>
            <video autoPlay muted loop
                style={{
                    position: 'fixed',
                    right: '0',
                    bottom: '0',
                    minWidth: '100%',
                    minHeight: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    zIndex: '1',
                    objectFit: 'cover'
                }}>
                <source src={`${process.env.PUBLIC_URL}/background.mp4`} type="video/mp4" />
            </video>

            <Col md={4}>
                
                <div style={{ backgroundColor: 'white', zIndex: '10', position: 'relative' }}>

                    {/* Header */}
                    <div style={{ padding: '1rem', backgroundColor: "rgb(2,10,61)" }}>
                        <span style={{ width: '100%' }}>
                            <img src={`${process.env.PUBLIC_URL}/main-icon.png`} className='img-fluid' alt='background' />
                        </span>
                    </div>

                    {/* Back Arrow */}
                    <div className="px-4 mt-4">
                            <ArrowLeft
                                className="fs-4 cursor-pointer"
                                onClick={() => navigate('/login')}
                                style={{ cursor: 'pointer', color: 'black' }} // 设置 color 为黑色
                            />
                        </div>

                    <div className="bg-white p-4 shadow">

                        {/* Form */}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-4 ml-8">
                                <label htmlFor="email" className="form-label">
                                    Account Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    maxLength={64}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control"
                                    placeholder="Enter your email here"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary w-100"
                                style={{ borderRadius: '0.5rem' }}
                            >
                                Send Email
                            </button>
                        </form>

                    </div>

                    {/* Success Message */}
                    {showSuccess && (
                        <div className="alert alert-success mt-4" role="alert">
                            Email sent successfully! Please check your inbox.
                        </div>
                    )}

                    {/* Error Message */}
                    {showError && (
                        <div className="alert alert-danger mt-4" role="alert">
                            {errorMessage}
                        </div>
                    )}
                </div>
            </Col>

        </div>
    );
};

export default ForgotPassword;