import React, { useState, useContext} from 'react';
import { Button, Col, FormControl, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppContext from '../context/AppContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link } from "react-router-dom";

function Login() {
  const { login } = useContext(AppContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    const result = await login(username, password);
    if (result.status) {
      setShowSuccess(true);
      console.log(result.endpoint);
      setTimeout(() => {
        if (result.endpoint === 'hotel') {
          navigate("/htlanding");
        } else if (result.endpoint === 'advertiser') {
          navigate("/adlanding");
        }
      }, 800);
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  };

  return (
    <div style={{ width: '100vw', height: '100vh' }} className='d-flex justify-content-center align-items-center'>
      <video autoPlay muted loop
        style={{
          position: 'fixed',
          right: '0',
          bottom: '0',
          minWidth: '100%',
          minHeight: '100%',
          maxWidth: '100%',
          maxHeight: '100%',
          zIndex: '1',
          objectFit: 'cover',
        }} >
        <source src={`${process.env.PUBLIC_URL}/background.mp4`} type="video/mp4" />
      </video>

      <Col md={4}>
        <div style={{ backgroundColor: 'white', zIndex: '10', position: 'relative' }}>
          <div style={{ padding: '1rem', backgroundColor: "rgb(2,10,61)" }}>
            <span style={{ width: '100%' }}>
              <img src={`${process.env.PUBLIC_URL}/main-icon.png`} className='img-fluid' alt='background' />
            </span>
          </div>

          <div style={{ padding: '0 1.5rem 0 1.5rem', color: '#707070' }}>
            <div style={{ fontSize: '1.8rem', padding: '1.5rem 0 1.5rem 0' }}>Welcome</div>
            <InputGroup className="mb-1" style={{ border: '1px solid #ced4da', borderRadius: '4px' }}>
              <FormControl
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </InputGroup>
            <div className="mb-3">Email</div>

            <InputGroup className="mb-1" style={{ border: '1px solid #ced4da', borderRadius: '4px' }}>

              <FormControl
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  border: 'none',
                  borderLeft: '1px solid #ced4da',
                  backgroundColor: 'transparent',
                  color: '#676A6D',
                  boxShadow: 'none',
                  borderRadius: '0',
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>

            </InputGroup>
            <div className="">Password</div>

            <div className="mb-2 text-end font-semibold text-black cursor-pointer">
              <Link to="/forgetpwd" className="custom-link">
                Forgot password
              </Link>
            </div>

            <Button variant="primary" type="submit" className="px-4 w-100 mb-4" style={{ borderRadius: '0' }}
              onClick={handleLogin}>
              Login
            </Button>
          </div>
        </div>

        {/* Success Message */}
        {showSuccess && (
          <div className="alert alert-success mt-4" role="alert" style={{ zIndex: '10' }} >
            Login successfully!
          </div>
        )}

        {/* Error Message */}
        {showError && (
          <div className="alert alert-danger mt-4" role="alert" style={{ zIndex: '10' }}>
            Email or Password is not correct! Please try again.
          </div>
        )}

      </Col>
    </div>
  );
}

export default Login;
