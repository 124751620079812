import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tokenParam = params.get('token') || 'Invalid info';
        setToken(tokenParam);

    }, [location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMessage('New Password and Confirm Password do not match.');
            setShowError(true);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ForgetPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    new_password: newPassword,
                    token: token,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setErrorMessage(errorData.detail || 'Failed to submit request. Please try again.');
                setShowError(true);
                setShowSuccess(false);
                return;
            }

            setShowSuccess(true);
            setShowError(false);
            setErrorMessage('');
            setNewPassword('');
            setConfirmPassword('');

            // 3秒后跳转到登录页面
            setTimeout(() => {
                navigate('/#/login'); // 导航到登录页面
            }, 5000);


        } catch (error) {
            console.error('Request error:', error);
            setShowError(true);
            setShowSuccess(false);
            setErrorMessage('Failed to submit request. Please try again.');
        }
    };

    const isFormValid = () => {
        return (
            email &&
            newPassword &&
            confirmPassword &&
            newPassword === confirmPassword
        );
    };

    return (

        <div style={{ width: '100vw', height: '100vh' }} className='d-flex justify-content-center align-items-center'>
            <video autoPlay muted loop
                style={{
                    position: 'fixed',
                    right: '0',
                    bottom: '0',
                    minWidth: '100%',
                    minHeight: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    zIndex: '1',
                    objectFit: 'cover'
                }}>
                <source src={`${process.env.PUBLIC_URL}/background.mp4`} type="video/mp4" />
            </video>

            <Col md={4}>

                <div style={{ backgroundColor: 'white', zIndex: '10', position: 'relative' }}>

                    {/* Header */}
                    <div style={{ padding: '1rem', backgroundColor: "rgb(2,10,61)" }}>
                        <span style={{ width: '100%' }}>
                            <img src={`${process.env.PUBLIC_URL}/main-icon.png`} className='img-fluid' alt='background' />
                        </span>
                    </div>

                    <div className="bg-white p-4 shadow">

                        {/* Form */}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-4 ml-8">
                                <label htmlFor="email" className="form-label">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    maxLength={64}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control"
                                    placeholder='Enter you email address'
                                    required
                                />
                            </div>

                            <div className="form-group mb-4 ml-8">
                                <label htmlFor="email" className="form-label">New Password</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        value={newPassword}
                                        maxLength={64}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="form-control"
                                        placeholder='Enter your new password'
                                        required
                                        style={{ flex: 1 }}
                                    />
                                    <Button
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{
                                            borderColor: '#ced4da',
                                            borderLeft: 'none',
                                            backgroundColor: 'transparent',
                                            color: '#676A6D',
                                            boxShadow: 'none',
                                            borderRadius: '0',
                                        }}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </Button>
                                </div>
                            </div>

                            <div className="form-group mb-4 ml-8">
                                <label htmlFor="email" className="form-label">Confirm Password</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type={showRepeatPassword ? "text" : "password"}
                                        value={confirmPassword}
                                        maxLength={64}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="form-control"
                                        placeholder='Enter your new password again'
                                        required
                                        style={{ flex: 1 }}
                                    />
                                    <Button
                                        onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                        style={{
                                            borderColor: '#ced4da',
                                            borderLeft: 'none',
                                            backgroundColor: 'transparent',
                                            color: '#676A6D',
                                            boxShadow: 'none',
                                            borderRadius: '0',
                                        }}
                                    >
                                        {showRepeatPassword ? <FaEyeSlash /> : <FaEye />}
                                    </Button>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary w-100"
                                style={{ borderRadius: '0.5rem' }}
                                disabled={!isFormValid()}
                            >
                                Submit
                            </button>
                        </form>
                    </div>

                    {/* Success Message */}
                    {showSuccess && (
                        <div className="alert alert-success mt-4" role="alert">
                            Password Reset successfully! This page will be redirected to login page in 3 seconds.
                        </div>
                    )}

                    {/* Error Message */}
                    {showError && (
                        <div className="alert alert-danger mt-4" role="alert">
                            {errorMessage}
                        </div>
                    )}
                </div>
            </Col>

        </div>
    );
};

export default ResetPassword;